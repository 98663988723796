/* ------------- RESUME ------------- */
.resume {
  margin-top: 25px;
}

.skillButtons {
  text-align: center;
}

.skillButtons .btn {
  margin-left: 5px;
  margin-bottom: 10px;
}

.resume-header {
  padding-left: 25px;
}

.resume-header > * {
  padding: 10px;
}

.resume h2 {
  text-align: center;
  padding-top: 10px;
}

.resume .skill-graph {
  text-align: center;
}

.resume .skill-graph .skill-graph-svg {
    display: block;
    height: 500px;
    width: 95vw;
}

.resume .jumbotron {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
}

.project-container {
    display: grid;
    grid-template-columns: auto auto;
}

/* Reconfiguration for phones */
@media (max-width: 700px)  {
    .project-container {
        grid-template-columns: auto;
    }
}

.project-item {
    margin: 20px;
}

.badge {
  margin: 1px 2px 1px 2px;
}
.skill-scale-list {
  margin-top: 15px;
}

/* ------------- MyTravel ------------- */
.my-travel {
  margin-top: 30px;
  text-align: center;
  height: 85vh;
  overflow-y: scroll;
}

.my-travel-map {
  height: 75vh;
  width: 100vw;
  cursor: pointer;
}

.my-travel-details {
  padding-top: 20px;
  padding-bottom: 20px;
}

/* ------------- FOOTER ------------- */
.footer {
    position: absolute;
    width: 100%;
    background-color: #343a40;
    padding: 15px;
    display: grid;
    grid-template-columns: auto auto;
}

.footer small {
    color: #f0f8ff;
}

.footer .social-media-links a {
    float: right;
    padding-left: 10px;
    padding-right: 25px;
}

/* ------------- TEMPORARY ------------- */
.todo {
  margin-top: 30px;
  text-align: center;
  height: 87vh;
}





/**************************/
/*     HomePage Theme     */
/**************************/
.p-large {
	font-size: 1.125rem;
	line-height: 1.75rem;
  color: #ffffff; 
	font: 400 1rem/1.625rem "Open Sans", sans-serif;
}

.h1-large {
  color: lightgray;
  font-weight: 700;
	font-size: 2.5rem;
	line-height: 3.25rem;
	letter-spacing: -0.2px;
}

/* ------------- Header ------------- */
.header {
	position: relative;
	padding-top: 6.5rem;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('/header-background.jpg') center center no-repeat;
	background-size: cover;
	text-align: center;
}

.header .text-container {
	margin-bottom: 4rem;
}

.header .p-large {
	margin-bottom: 2.125rem;
}

.header .image-container {
	max-width: 420px;
	margin-right: auto;
	margin-left: auto;
}

/* ------------- Media Queries ------------- */
/* Min-width 768px */
@media (min-width: 768px) {

}
/* end of min-width 768px */


/* Min-width 992px */
@media (min-width: 992px) {
	
	/* General Styles */
	.h1-large {
		font-size: 5rem;
		line-height: 5.75rem;
	}
	/* end of general styles */

	/* Header */
	.header {
		padding-top: 11rem;
	}
	/* end of header */
}
/* end of min-width 992px */


/* Min-width 1200px */
@media (min-width: 1200px) {

	/* Header */
	.header {
		padding-top: 13.5rem;
		padding-bottom: 13.5rem;
		text-align: left;
	}

	.header .text-container {
		margin-bottom: 0;
	}

	.header .image-container {
		position: absolute;
		z-index: 1;
		right: 47%;
		bottom: 0;
		width: 680px;
		max-width: 100%;
	}
	/* end of header */
}
/* end of min-width 1200px */
/* End of Home Page Theme */