/**************************/
/*     Mario Kart Theme   */
/**************************/

.mario-karty {
    background-color: #2C3539;
    height: 100%;
}
.welcome {
    padding-top: 25px;
}
.main-image {
    padding-bottom: 25px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.mario-karty > h1 {
    padding: 25px;
    text-align: center;
    color: red;
    font-family: 'Creepster', serif;
    font-weight: bold !important;
}

.radio, .name-input {
    color: white;
    padding: 0px 10px 0px 10px;
}

.mario-kart-radio {
    display: inline-flex;
    justify-content: center;
}

.mario-kart-submit, .name-input {
    text-align: center;
}

.mario-kart-responses {
    padding-bottom: 500px;
    color: red;
    text-align: center;
    font-family: 'Creepster', serif;
}

.submit-button {
	color: #fff !important;
	text-transform: uppercase;
	text-decoration: none;
	background: #60a3bc;
	padding: 10px 20px 10px 20px;
	border-radius: 20px;
	display: inline-block;
	border: none;
	transition: all 0.4s ease 0s;
}

.submit-button:hover {
	text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
	-webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	-moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
	transition: all 0.4s ease 0s;
}